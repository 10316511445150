





















































































import { Component, Vue, PropSync } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsPaciente } from "@/utils/utils-paciente";
import pacienteModule from "@/store/modules/paciente-module";
@Component({})
export default class dialog_paciente_ficha_data_extends extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_paciente", { type: Number }) syncedId_Paciente!: number;
  @PropSync("id_dieta", { type: Number }) syncedId_Dieta!: number;

  created() {
    pacienteModule.getpacienteById(this.syncedId_Paciente);
  }
  public get paciente() {
    return pacienteModule.paciente;
  }
  public ImgPaciente(paciente: paciente) {
    return UtilsPaciente.getrutaImagen(paciente);
  }
  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
}
